import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RoutingState } from '@shared/navigation/router-state';

export class RoutingSerializer implements RouterStateSerializer<RoutingState> {
  public serialize(routerState: RouterStateSnapshot): RoutingState {
    let state = routerState.root;
    let params = { ...state.params };
    while (state.firstChild) {
      state = state.firstChild;
      params = { ...params, ...state.params };
    }

    return {
      url: routerState.url,
      queryParams: routerState.root.queryParams,
      params
    };
  }
}

import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '../../store/state';
import { Injectable } from '@angular/core';
import { UserService } from '../user.service';
import { Observable } from 'rxjs';
import { authActions } from '../../auth/store/actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserEffects {
  public authorizeSuccess$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.authorizeSuccess),
      tap((action) => {
        localStorage.setItem('user_id', action.response.user.id.toString());
        localStorage.setItem('user_name', action.response.user.name);
        localStorage.setItem('user_role_id', action.response.user.roleId.toString());
      })
    ),
    { dispatch: false }
  );

  public unauthorize$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.unauthorize),
      tap(() => {
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_role_id');
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store<AppState>
  ) {
  }
}

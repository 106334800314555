import { Expose } from 'class-transformer';
import { NoteType } from '@shared/note/models/note-type';

export class Note {
  @Expose()
  public id: number;

  @Expose()
  public content: string;

  @Expose({ name: 'note_type' })
  public noteType: NoteType;

  @Expose({ name: 'note_referrer' })
  public noteReferrer: number;

  @Expose({ name: 'note_reference' })
  public noteReference: string;

  @Expose()
  public position: number;

  @Expose({ name: 'is_current' })
  public isCurrent: boolean;

  constructor(note?: Partial<Note>) {
    Object.assign(this, note);
  }
}

import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { toastrAnimation } from 'src/app/shared/toastr/toastr.animation';

@Component({
  selector: '[toast-component]',
  templateUrl: './toastr.html',
  styleUrls: ['./toastr.scss'],
  animations: toastrAnimation
})
export class ToastrComponent extends Toast {
  constructor(
    public toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
}

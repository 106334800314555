import { Address } from '@shared/address/models';
import { Model } from '@shared/api/models/model';
import { ContactReference } from '@shared/contact/models/contact-reference';
import { Media } from '@shared/media/models';
import { Note } from '@shared/note/models';
import { NoteType } from '@shared/note/models/note-type';
import { GovernanceStructType } from '@shared/project/models/governance-struct-type';
import { PlotType } from '@shared/project/models/plot-type';
import { Exclude, Expose, Transform, Type } from 'class-transformer';

export class Project extends Model {
  @Expose()
  public id: number;

  @Expose()
  public name: string;

  @Expose({ name: 'is_pm' })
  public isPM: boolean;

  @Expose({ name: 'is_oa' })
  public isOA: boolean;

  @Expose({ name: 'is_um' })
  public isUM: boolean;

  @Expose({ name: 'project_address' })
  @Type(() => Address)
  public address: Address;

  @Expose()
  public units: number;

  @Expose()
  public floors: number;

  @Expose({ name: 'aggregate_entitlement' })
  public entitlement: number;

  @Expose({ name: 'plan_registered_on' })
  @Transform(Model.transformDate)
  public planRegistered: string;

  @Expose({ name: 'certification_of_occupation_on' })
  @Transform(Model.transformDate)
  public certificationOfOccupation: string;

  @Expose({ name: 'project_plot_type' })
  public plotType: PlotType;

  @Expose({ name: 'plan_no' })
  public plotNumber: string;

  @Expose({ name: 'present_use' })
  public presentUse: string;

  @Expose({ name: 'oa_address' })
  @Type(() => Address)
  public OAAddress: Address;

  @Expose()
  public trn: string;

  @Expose({ name: 'project_governance_structure' })
  public governance: GovernanceStructType;

  @Expose()
  public grace: number;

  @Expose({ name: 'overdue_penalty' })
  public penalty: number;

  @Expose({ name: 'fy_start' })
  public financialYearStart: string;

  @Expose({ name: 'first_general_assembly_on' })
  public firstGeneralAssembly: string;

  @Expose({ name: 'last_general_assembly_on' })
  public lastGeneralAssembly: string;

  @Expose({ name: 'last_board_on' })
  public lastBoard: string;

  @Expose({ name: 'mollak_id' })
  public mollakId: string;

  @Expose()
  @Type(() => ContactReference)
  public contacts: Array<ContactReference>;

  @Expose()
  @Type(() => Note)
  public notes: Array<Note>;

  @Expose()
  @Type(() => Media)
  public documents: Array<Media>;

  @Exclude()
  public get businessLines(): Array<string> {
    const lines = [];
    this.isPM && lines.push('PM');
    this.isUM && lines.push('UM');
    this.isOA && lines.push('OA');
    return lines;
  }

  @Exclude()
  public get marketingDescription(): Note {
    return this.notes.find((n) => n.noteType === NoteType.MARKETING_DESCRIPTION) || new Note({
      content: '',
      noteType: NoteType.WARNING_MESSAGE
    });
  }

  @Exclude()
  public get warningMessage(): Note {
    return this.notes.find((n) => n.noteType === NoteType.WARNING_MESSAGE) || new Note({
      content: '',
      noteType: NoteType.WARNING_MESSAGE
    });
  }

  @Exclude()
  public get plainNotes(): Array<Note> {
    return this.notes.filter((n) => noteTypes.includes(n.noteType));
  }

  constructor(project?: Partial<Project>) {
    super(project);
  }
}

const noteTypes = [
  NoteType.PROJECT_NOTE,
  NoteType.UNIT_NOTE,
  NoteType.CONTACT_NOTE
];

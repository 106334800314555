import { EntityReference } from '@shared/api/types/entity-reference';
import { MediaType } from '@shared/media/enums';
import { Exclude, Expose, Type } from 'class-transformer';

export class Media {
  @Exclude()
  public file: File;

  @Expose()
  public id: number;

  @Expose()
  public link: string;

  @Expose()
  public name: string;

  @Expose({ name: 'owner_id' })
  public ownerId: string;

  @Expose({ name: 'is_public' })
  @Type(() => Number)
  public isPublic: boolean;

  @Expose({ name: 'media_type' })
  public mediaType: MediaType;

  @Expose({ name: 'media_reference' })
  public reference: EntityReference;

  @Expose({ name: 'media_referrer' })
  public referrerId: number;

  @Expose({ name: 'is_current' })
  public isCurrent: boolean;

  constructor(media?: Partial<Media>) {
    Object.assign(this, media);
  }
}

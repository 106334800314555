import { User } from '../models';

export class UserState {
  public user: User;

  constructor() {
    this.user = new User({
      id: Number(localStorage.getItem('user_id')),
      name: localStorage.getItem('user_name'),
      roleId: Number(localStorage.getItem('user_role_id'))
    });
  }
}

import { Injectable } from '@angular/core';
import { privateProjectsProjectPageActions } from '@app/private/projects/project/shared/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Project } from '@shared/project/models';
import { projectSelectors } from '@shared/project/store/selectors';
import { AppState } from '@shared/store/state';
import { Observable } from 'rxjs';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { authActions } from '../../auth/store/actions';
import { projectActions } from './actions';

@Injectable()
export class ProjectEffects {
  public updateProject$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(projectActions.updateProject),
      tap((action) => {
        localStorage.setItem('project_id', action.project.id.toString());
        localStorage.setItem('project_name', action.project.name);
        localStorage.setItem('project_is_pm', action.project.isPM.toString());
        localStorage.setItem('project_is_um', action.project.isUM.toString());
        localStorage.setItem('project_is_oa', action.project.isOA.toString());
      })
    ),
    { dispatch: false }
  );

  public unauthorize$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.unauthorize),
      tap(() => {
        this.clearProjectData();
      })
    ),
    { dispatch: false }
  );

  public deleteProjectSuccess$: Observable<[Action, Project]> = createEffect(
    () => this.actions$.pipe(
      ofType(privateProjectsProjectPageActions.deleteProjectSuccess),
      withLatestFrom(this.store.select(projectSelectors.selectProject)),
      filter(([action, project]) => action.projectId === project.id),
      tap(() => {
        this.clearProjectData();
      })
    ),
    { dispatch: false }
  );

  private clearProjectData(): void {
    localStorage.removeItem('project_id');
    localStorage.removeItem('project_name');
    localStorage.removeItem('project_is_pm');
    localStorage.removeItem('project_is_um');
    localStorage.removeItem('project_is_oa');
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>
  ) {
  }
}

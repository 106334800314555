import { Injectable } from '@angular/core';
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { Pagination } from '../api/models';
import { Project } from './models';
import { ProjectQueryParams } from '@shared/project/models';

@Injectable()
export class ProjectService {
  constructor(
    private apiService: ApiService
  ) {
  }

  public get(params?: ProjectQueryParams): Observable<Pagination<Project>> {
    return this.apiService.get<Pagination<Project>>('/projects', omitBy(classToPlain(params), isUndefined))
      .pipe(
        map((response) => {
          return plainToClassFromExist(new Pagination<Project>(Project), response, {
            excludeExtraneousValues: true
          });
        })
      );
  }

  public getAll(params?: ProjectQueryParams): Observable<Array<Project>> {
    return this.get(new ProjectQueryParams({ ...params, all: true }))
      .pipe(
        map((pagination) => pagination.data)
      );
  }

  public getOne(id: number, params?: ProjectQueryParams): Observable<Project> {
    return this.apiService.get<Project>(`/projects/${id}`, omitBy(classToPlain(params), isUndefined))
      .pipe(
        map((response) => plainToClass(Project, response, {
          excludeExtraneousValues: true
        }))
      );
  }

  public create(project: Project): Observable<Project> {
    return this.apiService.post<Project>('/projects', classToPlain(project))
      .pipe(
        map((response) => plainToClass(Project, response, {
          excludeExtraneousValues: true
        }))
      );
  }

  public update(project: Project): Observable<Project> {
    return this.apiService.put<void>(`/projects/${project.id}`, classToPlain(project))
      .pipe(
        map(() => project)
      );
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete<void>(`/projects/${id}`);
  }
}

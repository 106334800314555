import { AppState } from '../../store/state';
import { createSelector } from '@ngrx/store';
import { ProjectState } from './state';

const selectFeature = (state: AppState) => state.projectState;

export const projectSelectors = {
  selectProject: createSelector(
    selectFeature,
    (state: ProjectState) => state.project
  )
};

import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/state';
import { authSelectors } from '../store/selectors';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class UnauthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
  }

  public canActivate(): Observable<boolean> {
    return this.store
      .pipe(
        select(authSelectors.selectIsAuthenticated),
        tap((isAuthenticated) => {
          if (isAuthenticated) {
            this.router.navigate(['/']);
          }
        }),
        map((isAuthenticated) => !isAuthenticated)
      );
  }
}

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pageTitleActions } from './actions';
import { PageTitleService } from '../page-title.service';

@Injectable()
export class PageTitleEffects {
  public changePageTitle$: Observable<void> = createEffect(
    () => this.actions$.pipe(
      ofType(pageTitleActions.changePageTitle),
      mergeMap((action) => this.pageTitleService.setTitle(action.title))
    ),
    { dispatch: false }
  );

  constructor(
    private pageTitleService: PageTitleService,
    private actions$: Actions
  ) {
  }
}

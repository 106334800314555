import { QueryParams } from '../../api/models';
import { Expose } from 'class-transformer';

export class ContactReferenceQueryParams extends QueryParams {
  @Expose()
  public contacts?: Array<number>;

  @Expose({ name: 'user_id' })
  public userId?: number;

  constructor(data?: Partial<ContactReferenceQueryParams> ) {
    super(data);
  }
}

import { Building } from '@shared/building/models';
import { ContactReference } from '@shared/contact/models/contact-reference';
import { Note } from '@shared/note/models';
import { UnitSubtype, UnitType } from '@shared/unit/enums';
import { Expose, Type } from 'class-transformer';

export class Unit {
  @Expose()
  public id: number;

  @Expose()
  public floor: number;

  @Expose()
  public bedrooms: number;

  @Expose()
  public bathrooms: number;

  @Expose({ name: 'parking_of' })
  public parkingOf: number;

  @Expose({ name: 'building_id' })
  public buildingId: number;

  @Expose({ name: 'cost_center_id' })
  public costCenterId: number;

  @Expose({ name: 'portfolio_id' })
  public portfolioId: number;

  @Expose()
  public area: number;

  @Expose({ name: 'unit_subtype' })
  public unitSubtype: UnitSubtype;

  @Expose()
  public plot: string;

  @Expose({ name: 'dewa_id' })
  public dewaId: string;

  @Expose({ name: 'mollak_id' })
  public mollakId: string;

  @Expose({ name: 'unit_tenancy_status' })
  public unitTenancyStatus: string;

  @Expose()
  public name: string;

  @Expose({ name: 'unit_type' })
  public unitType: UnitType;

  @Expose({ name: 'is_managed' })
  public isManaged: boolean;

  @Expose()
  @Type(() => Note)
  public notes: Array<Note>;

  @Expose()
  @Type(() => ContactReference)
  public contacts: Array<ContactReference>;

  @Expose()
  @Type(() => Building)
  public building: Building;

  @Expose()
  @Type(() => Unit)
  public parkingSpaces: Array<Unit>;

  constructor(unit?: Partial<Unit>) {
    Object.assign(this, unit);
  }
}

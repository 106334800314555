import { BankAccount } from '@shared/bank-account/models';
import { ContactReference } from '@shared/contact-reference/models';
import { Note } from '@shared/note/models';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { map, pick, values } from 'lodash';
import { Address } from './address';
import { Email } from './email';
import { Phone } from './phone';
import { ServiceCategory } from '@app/shared/service-category/models';
import { Model } from '@shared/api/models/model';

export class Contact extends Model {
  @Expose()
  public id: number;

  @Expose()
  public name: string;

  @Expose({ name: 'middle_name' })
  public middleName: string;

  @Expose()
  public surname: string;

  @Expose({ name: 'arabic_name' })
  public arabicName: string;

  @Expose()
  public gender: string;

  @Expose()
  @Transform(Model.transformDate)
  public dob: string;

  @Expose()
  public trn: string;

  @Expose()
  public signature: string;

  @Expose({ name: 'contact_type' })
  public contactType: string;

  @Expose()
  public nationality: string;

  @Expose({ name: 'passport_number' })
  public passportNumber: string;

  @Expose({ name: 'trade_license_id' })
  public tradeLicenseId: string;

  @Expose({ name: 'mollak_id' })
  public mollakId: string;

  @Expose({ name: 'emirates_id' })
  public emiratesId: string;

  @Expose({ name: 'is_active' })
  public isActive: boolean;

  @Expose({ name: 'is_approved' })
  public isApproved: boolean;

  @Expose({ name: 'registered_on' })
  @Transform(Model.transformDate)
  public registeredOn: string;

  @Expose({ name: 'expiry_on' })
  @Transform(Model.transformDate)
  public expiryOn: string;

  @Expose({ name: 'vat_registration_number' })
  public vatRegistrationNumber: string;

  @Expose({ name: 'vendor_registration_number' })
  public vendorRegistrationNumber: string;

  @Expose({ name: 'contact_person' })
  public contactPerson: string;

  @Expose()
  @Type(() => Email)
  public emails: Array<Email>;

  @Expose()
  @Type(() => Phone)
  public phones: Array<Phone>;

  @Expose()
  @Type(() => Address)
  public addresses: Array<Address>;

  @Expose()
  @Type(() => Note)
  public notes: Array<Note>;

  @Expose()
  @Type(() => ContactReference)
  public projects: Array<ContactReference>;

  @Expose()
  @Type(() => ContactReference)
  public buildings: Array<ContactReference>;

  @Expose()
  @Type(() => BankAccount)
  public banks: Array<BankAccount>;

  @Expose()
  @Type(() => ServiceCategory)
  public categories: Array<ServiceCategory>;

  @Exclude()
  public get fullName(): string {
    return values(pick(this, ['name', 'middleName', 'surname'])).join(' ');
  }

  @Exclude()
  public get defaultEmail(): string {
    const email = this.emails.find((item) => item.isDefault);

    return email ? email.email : '';
  }

  @Exclude()
  public get otherEmails(): string {
    const emails = this.emails.filter((item) => !item.isDefault);

    return emails ? map(emails, 'email').join('<br/>') : '';
  }

  @Exclude()
  public get defaultPhone(): string {
    const phone = this.phones.find((item) => item.isDefault);

    return phone ? phone.number.toString() : '';
  }

  @Exclude()
  public get otherPhones(): string {
    const phones = this.phones.filter((item) => !item.isDefault);

    return map(phones, 'number').join('<br/>');
  }

  @Exclude()
  public get billingAddress(): string {
    const address = this.addresses.find((item) => item.isBilling);

    return address ? `${address.address1}, ${address.city}` : '';
  }
}

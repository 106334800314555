import { Action, createReducer, on } from '@ngrx/store';
import { ProjectState } from './state';
import { projectActions } from './actions';
import { authActions } from '../../auth/store/actions';
import { privateSidebarActions } from '@app/private/shared/sidebar/shared/store/actions';
import { first } from 'lodash';
import { privateProjectsCreateProjectDialogActions } from '@app/private/projects/shared/store/create-project-dialog/actions';
import { privateProjectsProjectPageActions } from '@app/private/projects/project/shared/store/actions';
import { privateProjectsPageActions } from '@app/private/projects/shared/store/projects/actions';

export const initialState = new ProjectState();

const reducer = createReducer(
  initialState,
  on(projectActions.updateProject, (state, action): ProjectState => ({
    ...state,
    project: action.project
  })),
  on(authActions.unauthorize, (state): ProjectState => ({
    ...state,
    project: null
  })),
  on(privateSidebarActions.loadProjectsSuccess, (state, action): ProjectState => ({
    ...state,
    project: (state.project) ? state.project : first(action.projects)
  })),
  on(privateProjectsPageActions.loadProjectsSuccess, (state, action): ProjectState => ({
    ...state,
    project: (state.project) ? state.project : first(action.projects)
  })),
  on(privateProjectsCreateProjectDialogActions.createProjectSuccess, (state, action): ProjectState => ({
    ...state,
    project: (state.project) ? state.project : action.project
  })),
  on(privateProjectsProjectPageActions.deleteProjectSuccess, (state, action): ProjectState => ({
    ...state,
    project: (state.project.id !== action.projectId) ? state.project : null
  }))
);

export function projectReducer(state: ProjectState, action: Action): ProjectState {
  return reducer(state, action);
}

import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { ToastMessageType } from './types';
import { environment } from '@environments/environment';

@Injectable()
export class ToastService {
  constructor(
    private toastrService: ToastrService
  ) {
  }

  public success<T>(translateMessageKey: string, translateTitleKey: string = '', options: any = {}): Observable<ActiveToast<T>> {
    return this.show('success', translateTitleKey, translateMessageKey, options);
  }

  public info<T>(translateMessageKey: string, translateTitleKey: string = '', options: any = {}): Observable<ActiveToast<T>> {
    return this.show('info', translateTitleKey, translateMessageKey, options);
  }

  public warning<T>(translateMessageKey: string, translateTitleKey: string = '', options: any = {}): Observable<ActiveToast<T>> {
    return this.show('warning', translateTitleKey, translateMessageKey, options);
  }

  public error<T>(translateMessageKey: string, translateTitleKey: string = '', options: any = {}): Observable<ActiveToast<T>> {
    return this.show('error', translateTitleKey, translateMessageKey, options);
  }

  private show<T>(type: ToastMessageType, translateTitleKey: string, translateMessageKey: string, options: any = {}): Observable<ActiveToast<T>> {
    return of(this.toastrService[type](translateMessageKey, translateTitleKey, { ...environment.toast, ...options }));
  }
}

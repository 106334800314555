import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard, UnauthenticatedGuard } from './shared/auth/guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'projects'
  },
  {
    path: '',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('src/app/private/private.module').then((module) => module.PrivateModule)
  },
  {
    path: '',
    canActivate: [UnauthenticatedGuard],
    loadChildren: () => import('src/app/public/public.module').then((module) => module.PublicModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Expose, Type } from 'class-transformer';

export class QueryParams {
  @Expose()
  @Type(() => Number)
  public all?: boolean;

  @Expose()
  public query?: string;

  @Expose()
  public with?: Array<string>;

  constructor(params?: Partial<QueryParams>) {
    Object.assign(this, params);
  }
}

import { Project } from '../models';
import { plainToClass } from 'class-transformer';

export class ProjectState {
  public project: Project;

  constructor() {
    const exists = Boolean(localStorage.getItem('project_id'));
    this.project = (exists)
      ? plainToClass<Project, Partial<Project>>(Project, {
        id: Number(localStorage.getItem('project_id')),
        name: localStorage.getItem('project_name'),
        isPM: localStorage.getItem('project_is_pm') === 'true',
        isUM: localStorage.getItem('project_is_um') === 'true',
        isOA: localStorage.getItem('project_is_oa') === 'true'
      }, { ignoreDecorators: true })
      : null;
  }
}

import { QueryParams } from '../../api/models';
import { Expose, Type } from 'class-transformer';

export class ProjectQueryParams extends QueryParams {
  @Expose({ name: 'is_pm' })
  @Type(() => Number)
  public isPM?: boolean;

  @Expose({ name: 'is_um' })
  @Type(() => Number)
  public isUM?: boolean;

  @Expose({ name: 'is_oa' })
  @Type(() => Number)
  public isOA?: boolean;

  constructor(params?: Partial<ProjectQueryParams>) {
    super(params);
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { errorActions } from '@shared/error/store/actions';
import { ToastService } from '@shared/toast/toast.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ErrorEffects {
  public handleError$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(errorActions.handleError),
      tap((action) => {
        this.toastService.error('ERRORS.TEXT_ERROR_RESPONSE', '', {
          message: action.response.error.message
        });
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private toastService: ToastService
  ) {
  }
}

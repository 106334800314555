import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { select, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ApiModule } from '@shared/api/api.module';
import { AuthModule } from '@shared/auth/auth.module';
import { AuthEffects } from '@shared/auth/store/effects';
import { authReducer } from '@shared/auth/store/reducer';
import { authSelectors } from '@shared/auth/store/selectors';
import { ErrorEffects } from '@shared/error/store/effects';
import { RoutingSerializer } from '@shared/navigation/serializer';
import { NavigationEffects } from '@shared/navigation/store/effects';
import { PageTitleModule } from '@shared/page-title/page-title.module';
import { PageTitleEffects } from '@shared/page-title/store/effects';
import { ProjectModule } from '@shared/project/project.module';
import { ProjectEffects } from '@shared/project/store/effects';
import { projectReducer } from '@shared/project/store/reducer';
import { AppState } from '@shared/store/state';
import { ToastModule } from '@shared/toast/toast.module';
import { ToastrComponent } from '@shared/toastr/toastr.component';
import { WebpackTranslateLoader } from '@shared/translate';
import { UserEffects } from '@shared/user/store/effects';
import { userReducer } from '@shared/user/store/reducer';
import { UserModule } from '@shared/user/user.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ToastrModule } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

export function jwtOptionsFactory(store: Store<AppState>): object {
  return {
    tokenGetter: () => {
      return store
        .pipe(
          select(authSelectors.selectToken),
          take(1)
        )
        .toPromise();
    },
    whitelistedDomains: environment.api.whitelisted_domains,
    blacklistedRoutes: environment.api.unauthorized_routes
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ToastrComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot({
      router: routerReducer,
      authState: authReducer,
      userState: userReducer,
      projectState: projectReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 30,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([
      AuthEffects,
      UserEffects,
      ProjectEffects,
      PageTitleEffects,
      NavigationEffects,
      ErrorEffects
    ]),
    StoreRouterConnectingModule.forRoot({
      serializer: RoutingSerializer
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Store]
      }
    }),
    ToastrModule.forRoot({
      toastComponent: ToastrComponent
    }),
    GooglePlaceModule,
    ApiModule,
    AuthModule,
    UserModule,
    ProjectModule,
    PageTitleModule,
    ToastModule
  ],
  entryComponents: [
    ToastrComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

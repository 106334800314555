import { createAction, props } from '@ngrx/store';

export const navigationActions = {
  mergeQueryParam: createAction(
    '[Navigation] Merge Query Parameter',
    props<{ name: string, value: any }>()
  ),
  mergeQueryParams: createAction(
    '[Navigation] Merge Query Parameters',
    props<{ queryParams: { [key: string]: any } }>()
  )
};

import { Expose } from 'class-transformer';

export class Phone {
  @Expose({ name: 'country_code' })
  public countryCode: number;

  @Expose()
  public number: string;

  @Expose()
  public extension: number;

  @Expose({ name: 'is_default' })
  public isDefault: boolean;

  @Expose({ name: 'is_whatsapp' })
  public isWhatsapp: boolean;
}

import { Action, createReducer, on } from '@ngrx/store';
import { plainToClass } from 'class-transformer';
import { UserState } from './state';
import { User } from '../models';
import { authActions } from '../../auth/store/actions';

export const initialState = new UserState();

const reducer = createReducer(
  initialState,
  on(authActions.authorizeSuccess, (state, action): UserState => ({
    ...state,
    user: action.response.user
  })),
  on(authActions.unauthorize, (state): UserState => ({
    ...state,
    user: plainToClass<User, Partial<User>>(User, {})
  })),
);

export function userReducer(state: UserState, action: Action): UserState {
  return reducer(state, action);
}

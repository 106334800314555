import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Project } from '../../../../../shared/project/models';

export const privateProjectsCreateProjectDialogActions = {
  refreshState: createAction(
    '[Private Projects Create Project Dialog] Refresh State'
  ),
  createProject: createAction(
    '[Private Projects Create Project Dialog] Create Project',
    props<{ dialogId: string }>()
  ),
  createProjectSuccess: createAction(
    '[Private Projects Create Project Dialog] Create Project Success',
    props<{ project: Project }>()
  ),
  createProjectFailure: createAction(
    '[Private Projects Create Project Dialog] Create Project Failure',
    props<{ response: HttpErrorResponse }>()
  )
};

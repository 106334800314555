import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Project } from '@shared/project/models';
import { QueryParams } from '@shared/api/models';

export const privateSidebarActions = {
  loadProjects: createAction(
    '[Private Sidebar] Load Projects'
  ),
  loadProjectsSuccess: createAction(
    '[Private Sidebar] Load Projects Success',
    props<{ projects: Array<Project> }>()
  ),
  loadProjectsFailure: createAction(
    '[Private Sidebar] Load Projects Failure',
    props<{ response: HttpErrorResponse }>()
  ),
  changeSearch: createAction(
    '[Private Sidebar] Change Search',
    props<{ queryParams?: QueryParams }>()
  ),
  refreshState: createAction(
    '[Private Sidebar] Refresh State'
  )
};

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class PageTitleService {
  constructor(
    private title: Title,
    private translateService: TranslateService
  ) {
  }

  public setTitle(translationKey: string, interpolateParams?: object): Observable<void> {
    return this.translateService.get(translationKey, interpolateParams)
      .pipe(
        map((title) => this.title.setTitle(title))
      );
  }
}

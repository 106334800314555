import { Expose } from 'class-transformer';

export class Address {
  @Expose()
  public address1: string;

  @Expose()
  public address2: string;

  @Expose()
  public city: string;

  @Expose()
  public state: string;

  @Expose()
  public country: string;

  @Expose()
  public pobox: string;

  @Expose({ name: 'is_billing' })
  public isBilling: boolean;

  @Expose({ name: 'is_delivery' })
  public isDelivery: boolean;
}

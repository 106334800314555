import { MultiselectOption } from '@shared/multiselect/shared/types';
import { ServiceCategory } from './service-category';
import { Expose, Transform } from 'class-transformer';

export class ServiceCategoryOption extends ServiceCategory implements MultiselectOption {
  @Expose({ toClassOnly: true })
  public translatedName: string;

  @Expose({ name: 'service_category_name' })
  @Transform((_, obj) => obj.id, { toPlainOnly: true })
  public serviceCategoryName: string;

  public getLabel(): string {
    return this.translatedName;
  }
}

import { ContactFunction } from '@shared/contact/enums';
import { Contact } from '@shared/contact/models/contact';
import { Exclude, Expose, Transform, Type } from 'class-transformer';

export class ContactReference {
  @Expose()
  public id: number;

  @Expose({ name: 'contact_id' })
  @Transform((_, obj) => obj.contact.id, { toPlainOnly: true })
  public contactId: number;

  @Expose({ name: 'contact_reference' })
  public reference: string;

  @Expose({ name: 'contact_reference_function' })
  public contactFunction: ContactFunction;

  @Expose()
  public referrer: number;

  @Expose()
  @Type(() => Contact)
  @Exclude({ toPlainOnly: true })
  public contact: Contact;

  constructor(reference?: Partial<ContactReference>) {
    Object.assign(this, reference);
  }
}

import { Expose } from 'class-transformer';
import { BankAccountTypesEnum } from '@shared/bank-account/enums';

export class BankAccount {
  @Expose()
  public id: number;

  @Expose({ name: 'contact_id' })
  public contactId: number;

  @Expose({ name: 'project_id' })
  public projectId: number;

  @Expose({ name: 'landlord_id' })
  public landlordId: number;

  @Expose()
  public branch: string;

  @Expose({ name: 'swift_code' })
  public swiftCode: string;

  @Expose({ name: 'account_name' })
  public accountName: string;

  @Expose({ name: 'account_number' })
  public accountNumber: string;

  @Expose({ name: 'iban_number' })
  public ibanNumber: string;

  @Expose()
  public currency: string;

  @Expose({ name: 'bank_name' })
  public bankName: string;

  @Expose({ name: 'bank_account_type' })
  public bankAccountType: BankAccountTypesEnum;

  @Expose({ name: 'is_current' })
  public isCurrent: boolean;
}

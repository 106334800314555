import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.html',
  styleUrls: ['./app.scss']
})
export class AppComponent {
  constructor(
    private translateService: TranslateService
  ) {
    // if you got a language switching ticket feel free to modify and extend this code
    const languages = environment.languages;
    const languageIds = Object.values(languages).map((language) => language.id);
    this.translateService.addLangs(languageIds);
    this.translateService.setDefaultLang(languages.english.id);
  }
}

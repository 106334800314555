import { AuthCredentials, AuthResponse } from '../models';
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const authActions = {
  authorize: createAction(
    '[Auth] Authorize',
    props<{ credentials: AuthCredentials }>()
  ),
  unauthorize: createAction(
    '[Auth] Unauthorize'
  ),
  authorizeSuccess: createAction(
    '[Auth] Authorize Success',
    props<{ response: AuthResponse }>()
  ),
  authorizeFailure: createAction(
    '[Auth] Authorize Failure',
    props<{ response: HttpErrorResponse }>()
  ),
  updateToken: createAction(
    '[Auth] Update Token',
    props<{ token: string }>()
  ),
  refreshToken: createAction(
    '[Auth] Refresh Token'
  ),
  refreshTokenFailure: createAction(
    '[Auth] Refresh Token Failure',
    props<{ response: HttpErrorResponse }>()
  )
};

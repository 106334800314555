import { QueryParams } from '../../api/models';
import { Expose } from 'class-transformer';

export class ContactQueryParams extends QueryParams {
  @Expose({ name: 'user_id' })
  public userId?: string | number;

  @Expose({ name: 'assigned_user' })
  public assignedUser?: number;

  @Expose({ name: 'contact_type' })
  public contactType?: string;

  constructor(data?: Partial<ContactQueryParams> ) {
    super(data);
  }
}

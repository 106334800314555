import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Address } from '@shared/address/models';
import { QueryParams } from '@shared/api/models';
import { Contact } from '@shared/contact/models';
import { ContactReference } from '@shared/contact/models/contact-reference';
import { Media } from '@shared/media/models';
import { Note } from '@shared/note/models';
import { Project } from '@shared/project/models';
import { FormArrayState } from 'ngrx-forms';

export const privateProjectsProjectPageActions = {
  loadProject: createAction(
    '[Private Project Page] Load Project'
  ),
  loadProjectSuccess: createAction(
    '[Private Project Page] Load Project Success',
    props<{ project: Project }>()
  ),
  loadProjectFailure: createAction(
    '[Private Project Page] Load Project Failure',
    props<{ response: HttpErrorResponse }>()
  ),
  loadContacts: createAction(
    '[Private Project Page] Load Contacts'
  ),
  loadContactsSuccess: createAction(
    '[Private Project Page] Load Contacts Success',
    props<{ contacts: Array<Contact> }>()
  ),
  loadContactsFailure: createAction(
    '[Private Project Page] Load Contacts Failure',
    props<{ response: HttpErrorResponse }>()
  ),
  saveProject: createAction(
    '[Private Project Page] Save Project'
  ),
  saveProjectSuccess: createAction(
    '[Private Project Page] Save Project Success',
    props<{ project: Project }>()
  ),
  saveProjectFailure: createAction(
    '[Private Project Page] Save Project Failure',
    props<{ response: HttpErrorResponse }>()
  ),
  searchContacts: createAction(
    '[Private Project Page] Search Contacts',
    props<{ queryParams?: QueryParams }>()
  ),
  cancelChanges: createAction(
    '[Private Project Page] Cancel Changes'
  ),
  changeNotes: createAction(
    '[Private Project Page] Change Notes',
    props<{ notes: FormArrayState<Note> }>()
  ),
  changeDocuments: createAction(
    '[Private Project Page] Change Documents',
    props<{ documents: Array<Media> }>()
  ),
  changeContacts: createAction(
    '[Private Project Page] Change Contacts',
    props<{ contacts: Array<ContactReference> }>()
  ),
  changeAddress: createAction(
    '[Private Project Page] Change Address',
    props<{ address: Address }>()
  ),
  openUploadDialog: createAction(
    '[Private Project Page] Open Upload Dialog'
  ),
  deleteProject: createAction(
    '[Private Project Page] Delete Project'
  ),
  deleteProjectSuccess: createAction(
    '[Private Project Page] Delete Project Success',
    props<{ projectId: number }>()
  ),
  deleteProjectFailure: createAction(
    '[Private Project Page] Delete Project Failure',
    props<{ response: HttpErrorResponse }>()
  ),
  refreshState: createAction(
    '[Private Project Page] Refresh State'
  )
};

import { Exclude, Expose, Type } from 'class-transformer';
import { Contact, Email } from '@shared/contact/models';
import { RolesEnum } from '@shared/user/enums';
import { ContactReference } from '@shared/contact-reference/models';

export class User {
  @Expose()
  public id: number;

  @Expose()
  public name: string;

  @Expose()
  public email: string;

  @Expose({ name: 'google_id' })
  public googleId: string;

  @Expose({ name: 'role_id' })
  public roleId: number;

  @Expose()
  @Type(() => Contact)
  public contacts: Array<Contact>;

  @Expose({ toPlainOnly: true })
  @Type(() => ContactReference)
  public permissions: Array<ContactReference>;

  @Exclude()
  public get roleName(): string {
    return 'COMMON.ROLES.' + (this.roleId === RolesEnum.administrator ? 'ADMINISTRATOR' : 'USER');
  }

  @Exclude()
  public get contactNames(): string {
    return this.contacts.map((contact) => contact.fullName).join(', ');
  }

  constructor(user?: Partial<User>) {
    Object.assign(this, user);
  }
}

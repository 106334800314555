import { Expose } from 'class-transformer';
import { compact, includes, isEmpty, isEqual } from 'lodash';
import { Address as PlacesAddress } from 'ngx-google-places-autocomplete/objects/address';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';

export class Address {
  @Expose()
  public location: string;

  @Expose()
  public city: string;

  @Expose()
  public state: string;

  @Expose()
  public street: string;

  @Expose()
  public country: string;

  @Expose()
  public zip_code: string;

  @Expose()
  public lat: number;

  @Expose()
  public lng: number;

  constructor(address?: Partial<Address>) {
    Object.assign(this, address);
  }

  public toString(): string {
    return compact([this.street, this.city, this.state, this.country]).join(', ');
  }

  public static fromPlacesAddress(address: PlacesAddress): Address {
    return this.fillAddressRelatedFields(new Address(), address);
  }

  private static defaultFields = ['location', 'country', 'city', 'street', 'state', 'zip_code', 'lat', 'lng'];

  private static fillAddressRelatedFields(entity: Address, address: PlacesAddress): Address {
    const fields = this.defaultFields;

    if (includes(fields, 'location')) {
      entity.location = address.formatted_address;
    }

    if (includes(fields, 'lat')) {
      entity.lat = address.geometry.location.lat();
    }

    if (includes(fields, 'lng')) {
      entity.lng = address.geometry.location.lng();
    }

    return this.prepareAddress(entity, address, fields);
  }

  private static prepareAddress(entity: Address, address: PlacesAddress, fields: Array<string>): Address {
    address.address_components.forEach((address_component) => {
      switch (address_component.types[0]) {
        case 'locality':
          this.setProperty(entity, 'city', fields, address_component);
          break;

        case 'route':
          if (includes(fields, 'street') && !isEqual(entity.street, address_component.long_name)) {
            entity.street = entity.street
              ? `${entity.street} ${address_component.long_name}`
              : address_component.long_name;
          }
          break;

        case 'street_number':
          this.setProperty(entity, 'street', fields, address_component);
          break;

        case 'administrative_area_level_1':
          this.setProperty(entity, 'state', fields, address_component);
          break;

        case 'postal_code':
          this.setProperty(entity, 'zip_code', fields, address_component);
          break;

        case 'country':
          this.setProperty(entity, 'country', fields, address_component);
          break;
      }
    });

    return entity;
  }

  private static setProperty(entity: Address, field: string, fields: Array<string>, addressComponent: AddressComponent): void {
    if (this.checkPropertyIsEqual(entity, field, fields, addressComponent)) {
      entity[field] = addressComponent.long_name;
    }
  }

  private static checkPropertyIsEqual(entity: Address, field: string, fields: Array<string>, addressComponent: AddressComponent): boolean {
    return includes(fields, field) && (isEmpty(entity[field]) || !isEqual(entity[field], addressComponent.long_name));
  }
}

import { AppState } from '../../store/state';
import { AuthState } from './state';
import { createSelector } from '@ngrx/store';

export const selectFeature = (state: AppState) => state.authState;

export const authSelectors = {
  selectToken: createSelector(
    selectFeature,
    (state: AuthState) => state.token
  ),
  selectIsTokenRefreshing: createSelector(
    selectFeature,
    (state: AuthState) => state.isTokenRefreshing
  ),
  selectIsAuthenticated: createSelector(
    selectFeature,
    (state: AuthState) => Boolean(state.token)
  )
};

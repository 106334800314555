import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from './state';
import { authActions } from './actions';

export const initialState = new AuthState();

const reducer = createReducer(
  initialState,
  on(authActions.updateToken, (state, action): AuthState => ({
    ...state,
    token: action.token,
    isTokenRefreshing: false
  })),
  on(authActions.authorizeSuccess, (state, action): AuthState => ({
    ...state,
    token: action.response.token,
    isTokenRefreshing: false
  })),
  on(authActions.refreshToken, (state): AuthState => ({
    ...state,
    isTokenRefreshing: true
  })),
  on(authActions.unauthorize, (state): AuthState => ({
    ...state,
    token: null
  }))
);

export function authReducer(state: AuthState, action: Action): AuthState {
  return reducer(state, action);
}

import { Expose, Transform, Type } from 'class-transformer';
import { QueryParams } from '../../api/models';

export class BuildingQueryParams extends QueryParams {
  @Expose({ name: 'project_id' })
  @Type(() => Number)
  public projectId?: number;

  @Expose({ name: 'with_details', toPlainOnly: true })
  @Transform((value) => (value) ? '1' : '0', { toPlainOnly: true })
  public withDetails?: boolean;

  @Expose({ name: 'present_use' })
  @Transform((value) => (value === '') ? undefined : value)
  public presentUse?: string;

  @Expose({ name: 'with_count', toPlainOnly: true })
  public withCount?: Array<string>;

  constructor(params?: Partial<BuildingQueryParams>) {
    super(params);
  }
}

import { Exclude, Expose, Type } from 'class-transformer';

export class Pagination<T> {
  @Expose()
  @Type((options) => (options.newObject as Pagination<T>).type)
  public data: Array<T>;

  @Expose()
  public total: number;

  @Exclude()
  private type: Function;

  constructor(type: Function) {
    this.type = type;
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { forOwn, isArray, keys } from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  public get<T>(endpoint: string, params: any = {}, options: Object = {}): Observable<T> {
    params = this.prepareHttpParams(params);

    return this.httpClient.get<T>(`${environment.api.url}${endpoint}`, {
      params,
      ...options
    });
  }

  public post<T>(endpoint: string, data: any = {}, options: Object = {}): Observable<T> {
    const params = (this.hasFiles(data)) ? this.convertToFormData(data) : data;

    return this.httpClient.post<T>(`${environment.api.url}${endpoint}`, params, options);
  }

  public put<T>(endpoint: string, data: any = {}, options: Object = {}): Observable<T> {
    return this.httpClient.put<T>(`${environment.api.url}${endpoint}`, data, options);
  }

  public delete<T>(endpoint: string, options: Object = {}): Observable<T> {
    return this.httpClient.delete<T>(`${environment.api.url}${endpoint}`, options);
  }

  private prepareHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();

    forOwn(params, (value, key) => {
      if (isArray(value)) {
        value.forEach((item) => {
          httpParams = httpParams.append(`${key}[]`, item);
        });
      } else {
        httpParams = httpParams.append(key, value);
      }
    });

    return httpParams;
  }

  private convertToFormData(data: object, isNested: boolean = false): FormData {
    const formData = new FormData();

    forOwn(data, (objectValue: any, objectKey) => {
      const appendKey = (isNested) ? `[${objectKey}]` : objectKey;
      if (Array.isArray(objectValue)) {
        objectValue.forEach((arrayValue, index) => {
          if (typeof arrayValue === 'object') {
            const formattedObject = this.convertToFormData(objectValue, true);
            formattedObject.forEach((formDataValue, formDataKey) => {
              formData.append(`${appendKey}${formDataKey}`, formDataValue);
            });
          } else {
            formData.append(`${appendKey}[${index}]`, arrayValue);
          }
        });
      } else if (objectValue instanceof File) {
        formData.append(appendKey, objectValue, objectValue.name);
      } else if (typeof objectValue === 'object') {
        const formattedObject = this.convertToFormData(objectValue, true);
        formattedObject.forEach((formDataValue, formDataKey) => {
          formData.append(`${appendKey}${formDataKey}`, formDataValue);
        });
      } else {
        formData.append(appendKey, objectValue);
      }
    });

    return formData;
  }

  private hasFiles(data: any): boolean {
    return typeof data === 'object' && keys(data).some((key) => ['file', 'files'].includes(key));
  }
}

import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { Contact } from '@shared/contact/models';
import { Building } from '@shared/building/models';
import { Project } from '@shared/project/models';
import { Unit } from '@shared/unit/models';
import { ContactFunction } from '@shared/contact/enums';

export class ContactReference {
  @Expose()
  public id: number;

  @Expose({ name: 'contact_id' })
  @Transform((_, obj) => obj.contact.id, { toPlainOnly: true })
  public contactId: number;

  @Expose({ name: 'contact_reference' })
  public contactReference: string;

  @Expose({ name: 'contact_reference_function' })
  public contactReferenceFunction: ContactFunction;

  @Expose()
  public referrer: number;

  @Expose()
  @Type(() => Contact)
  @Exclude({ toPlainOnly: true })
  public contact: Contact;

  @Expose()
  @Type(() => Building)
  public building: Building;

  @Expose()
  @Type(() => Project)
  public project: Project;

  @Expose()
  @Type(() => Unit)
  public unit: Unit;

  @Expose({ name: 'is_write', groups: ['permission'] })
  public isWrite: boolean;

  @Exclude()
  public get referenceName(): string {
    let name = '';

    if (this.building) {
      name = this.building.name;
    } else if (this.project) {
      name = this.project.name;
    } else if (this.unit) {
      name = this.unit.name;
    }

    return name;
  }

  constructor(reference?: Partial<ContactReference>) {
    Object.assign(this, reference);
  }
}

import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { Address } from '@shared/address/models';
import { Model } from '@shared/api/models/model';
import { Note } from '@shared/note/models';

export class Building extends Model {
  @Expose()
  public id: number;

  @Expose()
  public name: string;

  @Expose()
  public floors: number;

  @Expose({ name: 'project_id' })
  public projectId: number;

  @Expose({ name: 'cost_center_id' })
  public costCenterId: number;

  @Expose({ name: 'total_area' })
  public totalArea: number;

  @Expose({ name: 'present_use' })
  public presentUse: string;

  @Expose({ name: 'certification_of_occupation_on' })
  @Transform(Model.transformDate)
  public certificationOfOccupationOn: string;

  @Expose({ name: 'address' })
  @Type(() => Address)
  public address: Address;

  @Expose()
  @Type(() => Note)
  public notes: Array<Note>;
}

export const environment = {
  production: false,
  api: {
    url: 'https://dev.api.masterplan.kaizenams.com',
    authRedirect: '',
    whitelisted_domains: [
      'dev.api.masterplan.kaizenams.com'
    ],
    unauthorized_routes: [
      'https://dev.api.masterplan.kaizenams.com/login',
      'https://dev.api.masterplan.kaizenams.com/auth/forgot-password',
      'https://dev.api.masterplan.kaizenams.com/auth/restore-password',
      'https://dev.api.masterplan.kaizenams.com/auth/token/check'
    ]
  },
  languages: {
    english: {
      id: 'en',
      name: 'English'
    }
  },
  toast: {
    closeButton: true,
    timeOut: 40000,
    extendedTimeOut: 20000,
    positionClass: 'toast-bottom-right'
  }
};

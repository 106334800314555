import { TransformationType } from 'class-transformer/TransformOperationExecutor';
import * as moment from 'moment';

export class Model {
  constructor(model: Partial<Model>) {
    Object.assign(this, model);
  }

  protected static transformDate(value: string, obj: any, type: TransformationType): any {
    if (!value) {
      return value;
    }

    switch (type) {
      case TransformationType.CLASS_TO_PLAIN:
        return moment(value, ['YYYY-MM-DD', 'DD.MM.YYYY']).format('YYYY-MM-DD');
      case TransformationType.PLAIN_TO_CLASS:
        return moment(value, ['DD.MM.YYYY', 'YYYY-MM-DD']).format('DD.MM.YYYY');
      case TransformationType.CLASS_TO_CLASS:
        return value;
      default:
        return value;
    }
  }
}

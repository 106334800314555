import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Pagination, QueryParams } from '@shared/api/models';
import { Observable } from 'rxjs';
import { User, UserQueryParams } from '@shared/user/models';
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { map } from 'rxjs/operators';
import { isUndefined, omitBy } from 'lodash';

@Injectable()
export class UserService {
  constructor(
    private apiService: ApiService
  ) {
  }

  public get(params?: UserQueryParams): Observable<Pagination<User>> {
    return this.apiService.get<Pagination<User>>('/users', omitBy(classToPlain(params), isUndefined))
      .pipe(
        map((response) => {
          return plainToClassFromExist(new Pagination<User>(User), response, {
            excludeExtraneousValues: true
          });
        })
      );
  }

  public getAll(params?: UserQueryParams): Observable<Array<User>> {
    return this.get(new UserQueryParams({ ...params, all: true }))
      .pipe(
        map((pagination) => pagination.data)
      );
  }

  public getOne(id: number, params?: QueryParams): Observable<User> {
    return this.apiService.get<User>(`/users/${id}`, params)
      .pipe(
        map((response) => plainToClass(User, response))
      );
  }

  public create(user: User): Observable<User> {
    return this.apiService.post<User>('/users', classToPlain(user))
      .pipe(
        map((response) => plainToClass(User, response))
      );
  }

  public update(user: User): Observable<User> {
    return this.apiService.put<User>(`/users/${user.id}`, classToPlain(user))
      .pipe(
        map((response) => plainToClass(User, response, {
          excludeExtraneousValues: true
        }))
      );
  }
}

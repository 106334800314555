import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authActions } from './actions';
import { AuthResponse } from '../models';
import { AuthService } from '../auth.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
  public authorize$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.authorize),
      switchMap((action) => {
        return this.authService
          .authorize(action.credentials)
          .pipe(
            map((response: AuthResponse) => authActions.authorizeSuccess({ response })),
            catchError((response: HttpErrorResponse) => of(authActions.authorizeFailure({ response })))
          );
      })
    )
  );

  public authorizeSuccess$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.authorizeSuccess),
      tap((action) => {
        localStorage.setItem('token', action.response.token);

        this.router.navigate(['/projects']);
      })
    ),
    { dispatch: false }
  );

  public unauthorize$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.unauthorize),
      tap(() => {
        localStorage.removeItem('token');

        this.router.navigate(['/login']);
      })
    ),
    { dispatch: false }
  );

  public refreshToken$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.refreshToken),
      switchMap(() => {
        return this.authService
          .refreshToken()
          .pipe(
            map((response) => {
              const token = response.headers.get('Authorization').split(' ')[1];
              return authActions.updateToken({ token });
            }),
            catchError((response: HttpErrorResponse) => of(authActions.refreshTokenFailure({ response })))
          );
      })
    )
  );

  public updateToken$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.updateToken),
      tap((action) => {
        localStorage.setItem('token', action.token);
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {
  }
}

import { UnitType } from '@shared/unit/enums';
import { Expose, Type } from 'class-transformer';
import { QueryParams } from '../../api/models';

export class UnitQueryParams extends QueryParams {
  @Expose({ name: 'project_id' })
  @Type(() => Number)
  public projectId?: number;

  @Expose({ name: 'building_id' })
  @Type(() => Number)
  public buildingId?: number;

  @Expose({ name: 'unit_type' })
  public unitType?: UnitType;

  constructor(params?: Partial<UnitQueryParams>) {
    super(params);
  }
}
